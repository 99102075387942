<template>
  <el-dialog
    center
    @open="initData"
    :title="$t('История изменений')"
    :visible.sync="modals[nameModal]">
    <table-history :tableData="loadData"
                   :fieldInfoList="fieldInfoList"
                   :load="load"
                   :updateData="updateData"
                   :searchStart="searchStart"
                   :sortData="sortData"
                   :setSort="demo"
                   :backData="backData"
                    configName = "codesAgent"
    >
    </table-history>
    <span slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="toggleModal(nameModal)">Закрыть</button>
            </span>
  </el-dialog>
</template>
<script>
  import {HISTORY_CODE,  RETURN_CODE} from "./store/type";
  import {historyModal} from "../../../mixins/history/HistoryModal";

  export default {
    name: 'HistoryCode',
    mixins: [historyModal],
    data : function () {
      return {
        history : HISTORY_CODE,
        return : RETURN_CODE,
        paramsReturn : {},
        paramsGet : {
          CodeId: this.$route.params.CodeId,
          self: this
        }
      }
    },
  }
</script>
