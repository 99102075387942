<template>
  <div>
    <show-code :code="fieldInfoList"></show-code>
    <show-code :code="preResult"></show-code>
    <load-block :load="!load"></load-block>
    <the-edit-page
      :scheme="scheme"
      :isEntity="true"
      :loadData="loadData"
      v-if="load"
      :title="title"
      :pageMode="pageMode"
    >
      <the-controls-edit-page
        :loadData="loadData"
        :saveData="saveData"
        :pathBack="pathBack"
        :show-history="!($route.name == 'CreateCode')"
        :open-history="openHistory"
         :hasHistory="false"
        configName="codesAgent"
        :roleName="nameRoleEntity"
        :pageMode="pageMode"
        :wait="wait"
      ></the-controls-edit-page>
      <template name="block2">
        <code-enum v-if="loadData.dataTypeCode == typeEnum" />
      </template>
    </the-edit-page>
    <history-code
      v-if="!($route.name == 'CreateCode')"
      :modals="modals"
      :toggle-modal="toggleModal"
      :closeHistory="closeHistory"
    />
  </div>
</template>
<script>
import TheEditPage from "../../TheEditPage.vue";
import { ENTITY_NAME } from "./store/type";
import { READ, UPDATE } from "../../../store/type";
import TheControlsEditPage from "../../TheControlsEditPage.vue";
import clearReactive from "../../../helpers/clearReactive";
import ShowCode from "../../shared/ShowCode.vue";
import convertFieldInfoListToScheme from "../../../helpers/converters/convetFieldInfoListToScheme";
import clearNull from "../../../../src/helpers/clearNull";
import { mapState } from "vuex";

import {
  TYPE_ERRORS,
  FIELD_LANG_CODE,
  TYPE_CODE_ENUM
} from "../../../constant";
import notify from "../../../helpers/notify/notify";
import CodeEnum from "./codeEnum/CodeEnum.vue";
import LoadBlock from "../../shared/LoadBlock.vue";
import { checkValidate } from "../../../mixins/validate/checkValidate";
import HistoryCode from "../history/HistoryCode.vue";
import { pageMode } from "../../../mixins/editPage/pageMode";
import {NAME_OBJECT} from "../../../RoleConstanans"
export default {
  name: "EditPageCode",
  mixins: [checkValidate, pageMode],
  components: {
    HistoryCode,
    LoadBlock,
    CodeEnum,
    ShowCode,
    TheControlsEditPage,
    TheEditPage
  },
  data() {
    return {
      wait: false,
      pathBack: `/code`,
      typeEnum: TYPE_CODE_ENUM,
      scheme: {},
      loadData: null,
      fieldInfoList: null,
      preResult: null,
      load: true,
      modals: {
        history: false
      },
      routeNameShow: "CodeShow",
      routeNameCreate: "CreateCode"
    };
  },
  computed: {
    ...mapState({
      lang: state => state.common.lang
    }),
     nameRoleEntity(){
        return NAME_OBJECT.codesAgent
      },
    title() {
      return this.pageTitle(
        this.$t("Просмотр кода"),
        this.$t("Создание кода"),
        this.$t("Редактирование кода")
      );
    }
  },
  watch: {
    lang() {
      this.initData();
    }
  },
  created() {
    this.initData();
  },
  methods: {
    toggleModal(name) {
      this.modals[name] = !this.modals[name];
    },
    openHistory() {
      this.toggleModal("history");
    },
    closeHistory() {
      this.toggleModal("history");
      this.initData();
    },
    initData() {
      this.load = false;
      this.$store
        .dispatch(ENTITY_NAME + READ, {
          id: this.$route.params.CodeId || "0",
          self: this
        })
        .then(res => {
          this.loadData = res.object;
          let fieldInfoList = res.fieldInfoList;
          const hasReadonly = !(this.$route.name == "CreateCode");

          this.fieldInfoList = fieldInfoList; //del in future
          this.scheme.configForm = convertFieldInfoListToScheme({
            fieldInfoList,
            langFieldName: FIELD_LANG_CODE,
            hasReadonly: hasReadonly
          });
          this.preResult = convertFieldInfoListToScheme({
            fieldInfoList,
            langFieldName: FIELD_LANG_CODE,
            hasReadonly: hasReadonly
          }); //del in future
          this.load = true;
        });
    },
    saveData() {
      if (this.validateData() == false) {
        return;
      }
      let saveData = clearReactive(this.loadData);
      saveData = clearNull(saveData);
      this.wait = true;
      this.$store
        .dispatch(ENTITY_NAME + UPDATE, {
          id: this.$route.params.CodeId,
          saveData: saveData,
          self: this
        })
        .then(res => {
          this.wait = false;
          if (res.errors.length == 0) {
            notify(this, this.$t("Успешно"), TYPE_ERRORS.SUCCESS);
            if (typeof res.object == "number") {
              if (this.$route.name == "CreateCode") {
                this.$router.push({ path: `/code/${res.object}/edit` });
              }
            } else {
              this.$router.push({ path: this.pathBack });
            }
          }
        })
        .catch(err => {
          this.wait = false;
        });
    }
  }
};
</script>
